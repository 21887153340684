import React from 'react';
import PropTypes from 'prop-types';

function MetaContent({
    content, description1, roles, title1, title2,
}) {
    return (
        <>
            <div className="c-meta-content b-section">
                <div className="c-meta-content__aside">
                    <section className="c-meta-content__section">
                        {title1 && (
                            <h2 className="c-meta-content__title">{title1}</h2>
                        )}
                        {description1 && (
                            <p className="c-meta-content__description">{description1}</p>
                        )}
                    </section>

                    <section className="c-meta-content__section">
                        {title2 && (
                            <h2 className="c-meta-content__title">{title2}</h2>
                        )}
                        {roles[0].role && (
                            <ul className="c-meta-content__list">
                                {roles.map(({ role }) => (
                                    <li className="c-meta-content__item" key={role.id}>{role.document[0].data.name}</li>
                                ))}
                            </ul>
                        )}
                    </section>
                </div>

                {content && (
                    <div className="c-meta-content__content" dangerouslySetInnerHTML={{ __html: content }} />
                )}
            </div>
        </>
    );
}

MetaContent.propTypes = {
    content: PropTypes.string.isRequired,
    description1: PropTypes.string.isRequired,
    roles: PropTypes.array.isRequired,
    title1: PropTypes.string.isRequired,
    title2: PropTypes.string.isRequired,
};

export default MetaContent;
