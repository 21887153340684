import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import 'scss/main.scss';
import { SEO } from 'components';
import Nav from '../components/nav';
import MastheadCover from '../components/masthead-cover';
import MetaContent from '../components/meta-content';
import LinkOpacity from '../components/link-opacity';
import { Image } from '../components/image';

function WorkArticlePage({ data: { prismicWorkArticle }, location }) {
    const { data, lang } = prismicWorkArticle;
    const langUrl = (lang === 'en-gb') ? '' : `${lang.substring(0, lang.indexOf('-'))}/`;

    console.log(data)

    return (
        <>
            <SEO
                description={data.page_meta_description}
                lang={lang}
                pathname={location.pathname}
                socialImage={data.social_share_image.url}
                title={data.client}
            />

            <Nav lang={lang} />

            <div className="b-page">
                <div className="b-wrap">
                    <div className="b-page__wrap">
                        <MastheadCover
                            image={data.image}
                            alt={data.image.alt}
                            title={data.title.text}
                            client={data.client}
                            colour={data.transition_colour}
                            video={data.video.url}
                        />

                        <MetaContent
                            title1={data.overview_title}
                            description1={data.overview_description}
                            title2={data.role_title}
                            roles={data.roles}
                            content={data.description.html}
                        />

                        {data.body && data.body.map(({ primary }, index) => (
                            primary.image_component && (
                                <Image key={index} className="c-img b-section--top" image={primary.image_component} alt={primary.image_component.alt} size='xlarge' video={primary.image_video.url} />
                                // <Img key={index} className="c-img b-section--top" fluid={primary.image_component.url} alt={primary.image_component.alt} />
                            )
                        ))}

                        {data.image_credit && (
                            <div className="b-section--small" dangerouslySetInnerHTML={{ __html: data.image_credit.html }} />
                        )}

                        <div className="c-cta b-section">
                            <LinkOpacity
                                linkClass="c-cta__link"
                                text="Next project"
                                url={`/${langUrl}${data.next_article.document[0].data.client.replace(/\s+/g, '-').toLowerCase()}`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

WorkArticlePage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

export const query = graphql`
    query($id: String) {
        prismicWorkArticle(id: { eq: $id }) {
            lang
            uid
            data {
                page_meta_description
                social_share_image {
                    url
                }
                image {
                    url
                    alt
                    dimensions {
                        height
                        width
                    }
                }
                video {
                    url
                }
                title {
                    text
                }
                client
                transition_colour
                overview_title
                overview_description
                role_title
                roles {
                    role {
                        id
                        document {
                            __typename
                            ... on PrismicSkill {
                                data {
                                    name
                                }
                            }
                        }
                    }
                }
                description {
                    html
                }
                image_credit {
                    html
                }
                body {
                    ... on PrismicWorkArticleBodyImage {
                        primary {
                            image_component {
                                url
                                alt
                                dimensions {
                                    height
                                    width
                                }
                            }
                            image_video {
                                url
                            }
                        }
                    }
                }
                next_article {
                    document {
                        data {
                            client
                            transition_colour
                        }
                    }
                }
            }
        }
    }
`;

export default WorkArticlePage;
