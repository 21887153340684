import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Image } from './image';


function MastheadCover({
    alt, client, colour, image, title, video,
}) {
    return (
        <>
            <header className="c-masthead-cover b-section--bottom">
                <div className="c-masthead-cover__content">
                    <h1 className="c-masthead-cover__title">{title}</h1>
                    <span className="c-masthead-cover__subtitle">{client}</span>
                </div>
                <div className="c-masthead-cover__media">
                    {image && (
                        <Image className="c-masthead-cover__img" image={image} alt={alt} size='xlarge' style={{ backgroundColor: colour ? colour : 'none' }} />
                        // <Img className="c-masthead-cover__img" fluid={image.url} alt={alt} />
                    )}
                    {video && (
                        <video autoPlay={true} className="c-masthead-cover__video" loop={true} muted={true} playsInline={true} src={video} />
                    )}
                </div>
            </header>
        </>
    );
}

MastheadCover.defaultProps = {
    alt: '',
    colour: '',
};

MastheadCover.propTypes = {
    alt: PropTypes.string,
    client: PropTypes.string.isRequired,
    colour: PropTypes.string,
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
};

export default MastheadCover;
